import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'input',
    'preview',
    'removeButton',
    'removeInput'
  ]

  preview(event) {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = (e) => {
      this.previewTarget.src = e.target.result
      this.previewTarget.classList.remove('hidden')
      this.removeButtonTarget.classList.remove('hidden')
      this.removeInputTarget.value = '0'
    }

    reader.readAsDataURL(file)
  }

  remove() {
    this.previewTarget.src = ''
    this.inputTarget.value = ''
    this.removeButtonTarget.classList.add('hidden')
    this.removeInputTarget.value = '1'
  }
}
