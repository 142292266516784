import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'results',
    'input',
    'idInput'
  ]
  static values = {
    options: String
  }

  connect() {
    this.options = JSON.parse(this.optionsValue)
  }

  closeDropdown() {
    this.resultsTarget.innerHTML = ''
    this.resultsTarget.classList.add('hidden')
  }

  setCustomer(event) {
    const customerId = event.target.dataset.id
    this.inputTarget.value = this.options.find(option => option.id === customerId).info

    if (this.hasIdInputTarget) {
      this.idInputTarget.value = customerId
    }

    this.closeDropdown()
  }

  search(event) {
    const query = event.target.value

    if (query.length === 0) {
      this.closeDropdown()
      return
    }

    const results = this.options.filter(option => option.name.toLowerCase().includes(query.toLowerCase()))
    this.resultsTarget.innerHTML = results.map(result => {
      return `<div class="p-2 hover:font-medium"
                   data-id="${result.id}"
                   data-action="click->customer-autocomplete#setCustomer">
                ${result.name}
              </div>`
    }).join('')
    this.resultsTarget.classList.remove('hidden')
  }
}
