import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'records',
    'template'
  ]
  static values = {
    wrapperClass: String,
    insertPosition: String
  }

  connect() {
    this.wrapperClass = this.wrapperClassValue || 'nested-fields'
  }

  addAssociation(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/{{index}}/g, new Date().getTime())
    const insertPosition = this.insertPositionValue || 'beforebegin'
    this.recordsTarget.insertAdjacentHTML(insertPosition, content)
  }

  removeAssociation(event) {
    event.preventDefault()

    let wrapper = event.target.closest('.' + this.wrapperClass)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
}
