import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js/auto'

export default class extends Controller {
  static targets = [
    'trendChart',
    'averageTimeChart'
  ]
  static values = {
    trendChartData: String,
    averageTimeChartData: String
  }

  connect() {
    this.trendChartData = JSON.parse(this.trendChartDataValue)
    this.averageTimeChartData = JSON.parse(this.averageTimeChartDataValue)
    this.drawTrendChart()
    this.drawAverageTimeChart()
  }

  drawTrendChart() {
    new Chart(
      this.trendChartTarget.getContext('2d'),
      {
        type: 'line',
        data: {
          labels: this.trendChartData.map(item => item.period),
          datasets: [
            {
              label: 'Outstanding invoices',
              data: this.trendChartData.map(item => item.outstanding),
              borderColor: 'rgb(47,69,231)',
              backgroundColor: 'rgba(47,69,231,0.2)',
              fill: true
            },
            {
              label: 'Paid invoices',
              data: this.trendChartData.map(item => item.paid),
              borderColor: 'rgb(2,195,27)',
              backgroundColor: 'rgba(2,195,27,0.2)',
              fill: true
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Amount'
              },
              ticks: {
                callback: function(value, index, values) {
                  return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(value);
                }
              }
            },
            x: {
              title: {
                display: true,
                text: 'Month/Year'
              }
            }
          },
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(context.raw)
                }
              }
            }
          }
        }
      }
    )
  }

  drawAverageTimeChart() {
    new Chart(
      this.averageTimeChartTarget.getContext('2d'),
      {
        type: 'line',
        data: {
          labels: this.averageTimeChartData.map(item => item.period),
          datasets: [
            {
              label: 'Average time to pay',
              data: this.averageTimeChartData.map(item => item.average_time),
              borderColor: 'rgb(251,126,40)',
              backgroundColor: 'rgba(251,126,40,0.2)',
              fill: true
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Days'
              }
            },
            x: {
              title: {
                display: true,
                text: 'Month/Year'
              }
            }
          },
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return `${context.raw} day(s)`
                }
              }
            }
          }
        }
      }
    )
  }
}
