import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'instance',
    'background',
    'backdrop',
    'content'
  ]

  open(event) {
    event.preventDefault()
    const url = event.currentTarget.getAttribute("href")

    fetch(
      url, {
        headers: {
          'Accept': 'text/vnd.turbo-stream.html'
        }
      }
    )
      .then(response => response.text())
      .then(html => {
        this.contentTarget.innerHTML = html
        this.show()
      })
  }

  show() {
    this.backdropTarget.classList.remove('hidden')
    this.instanceTarget.classList.remove('hidden')
    document.addEventListener('click', this.closeOnOutsideClick.bind(this))
    document.addEventListener('turbo:stream:before-render', this.closeOnStreamEvent.bind(this))
  }

  close() {
    this.contentTarget.innerHTML = ''
    this.backdropTarget.classList.add('hidden')
    this.instanceTarget.classList.add('hidden')
    document.removeEventListener('click', this.closeOnOutsideClick.bind(this))
  }

  closeOnOutsideClick(event) {
    if (event.target === this.backgroundTarget) {
      this.close()
    }
  }

  closeOnStreamEvent(event) {
    console.log(event)
    if (event.detail.target.getAttribute('id') === 'modal') {
      this.close()
    }
  }
}
