import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['logoOption', 'fileField']

  toggleLogoOption(selectedLogoOption) {
    if (selectedLogoOption === 'custom') {
      this.fileFieldTarget.classList.remove('hidden')
    } else if (selectedLogoOption === 'default') {
      this.fileFieldTarget.classList.add('hidden')
    } else {
      this.fileFieldTarget.classList.add('hidden')
    }
  }

  connect() {
    this.logoOptionTargets.forEach((logoOption) => {
      if (logoOption.checked) {
        this.toggleLogoOption(logoOption.value)
      }
    })
  }

  handleLogoOptionChange(event) {
    const selectedLogoOption = event.target.value
    this.toggleLogoOption(selectedLogoOption)
  }
}
