import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'taxInput',
    'discountInput',
    'subtotal',
    'total',
    'balanceDue'
  ]

  connect() {
    this.discount = 0
    this.tax = 0
    this.shipping = 0
    this.amountPaid = 0
    this.subtotal = 0
    this.discountedSubtotal = 0
    this.total = 0
    this.balanceDue = 0
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.removedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('line-item')) {
            this.updateTotals()
          }
        })
      })
    })
    const selectedTaxKind = document.querySelector('#invoice_tax_kind').value
    const selectedDiscountKind = document.querySelector('#invoice_discount_kind').value
    this.handleTaxByKind(selectedTaxKind)
    this.handleDiscountByKind(selectedDiscountKind)
    this.calculateLineItemsTotal()
    this.observer.observe(document.querySelector('.line-items'), { childList: true, subtree: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  formatAsMoney(value) {
    return Intl.NumberFormat(
      'en-US',
      {
        style: 'currency',
        currency: 'USD'
      }
    ).format(value)
  }

  parseAsNumber(value) {
    const parsedValue = parseFloat(value)

    return isNaN(parsedValue) ? 0 : parsedValue
  }

  getDiscountValue() {
    const value = document.querySelector('.discount').value
    const discountKind = document.querySelector('#invoice_discount_kind').value

    return this.parseAsNumber(discountKind === 'percentage_discount' ? this.subtotal * value / 100 : value)
  }

  getTaxValue() {
    const value = document.querySelector('.tax').value
    const taxKind = document.querySelector('#invoice_tax_kind').value

    return this.parseAsNumber(taxKind === 'percentage_tax' ? this.discountedSubtotal * value / 100 : value)
  }

  getShippingValue() {
    return this.parseAsNumber(document.querySelector('.shipping').value)
  }

  getAmountPaid() {
    return this.parseAsNumber(document.querySelector('.amount-paid').value)
  }

  calculateLineItemsTotal() {
    const lineItems = document.querySelectorAll('.line-item')

    lineItems.forEach((lineItem) => {
      const quantity = this.parseAsNumber(lineItem.querySelector('.quantity').value)
      const price = this.parseAsNumber(lineItem.querySelector('.unit-price').value)
      const total = quantity * price
      lineItem.querySelector('.total').textContent = this.formatAsMoney(total)
    })

    this.updateTotals()
  }

  updateTotals() {
    const lineItemTotals = document.querySelectorAll('.line-item .total')
    this.subtotal = Array.from(lineItemTotals).reduce((sum, total) => {
      return sum + this.parseAsNumber(total.textContent.replace(/[^0-9.-]+/g, ''))
    }, 0)
    this.discount = this.getDiscountValue()
    this.discountedSubtotal = this.subtotal - this.discount
    this.shipping = this.getShippingValue()
    this.amountPaid = this.getAmountPaid()
    this.tax = this.getTaxValue()
    this.total = this.discountedSubtotal + this.tax + this.shipping
    this.balanceDue = this.total - this.amountPaid
    this.subtotalTarget.textContent = this.formatAsMoney(this.subtotal)
    this.totalTarget.textContent = this.formatAsMoney(this.total)
    this.balanceDueTarget.textContent = this.formatAsMoney(this.balanceDue)
  }

  updateLineItemTotal(event) {
    const lineItem = event.target.closest('.line-item')
    const quantity = lineItem.querySelector('.quantity').value
    const price = lineItem.querySelector('.unit-price').value
    const total = quantity * price
    lineItem.querySelector('.total').textContent = this.formatAsMoney(total)
    this.updateTotals()
  }

  handleTaxByKind(selectedTax) {
    if (selectedTax === 'amount_tax') {
      this.taxInputTarget.id = 'invoice_tax'
      this.taxInputTarget.name = 'invoice[tax]'
    } else {
      this.taxInputTarget.id = 'invoice_tax_percentage'
      this.taxInputTarget.name = 'invoice[tax_percentage]'
    }

    this.updateTotals()
  }

  handleDiscountByKind(selectedDiscount) {
    if (selectedDiscount === 'amount_discount') {
      this.discountInputTarget.id = 'invoice_discount'
      this.discountInputTarget.name = 'invoice[discount]'
    } else {
      this.discountInputTarget.id = 'invoice_discount_percentage'
      this.discountInputTarget.name = 'invoice[discount_percentage]'
    }

    this.updateTotals()
  }

  toggleTaxField(event) {
    this.handleTaxByKind(event.target.value)
  }

  toggleDiscountField(event) {
    this.handleDiscountByKind(event.target.value)
  }
}
