import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'source',
    'trigger'
  ]

  copy() {
    const text = this.sourceTarget.value
    navigator.clipboard.writeText(text).then(() => {
      const originalContent = this.triggerTarget.innerHTML
      this.triggerTarget.innerHTML = 'Copied!'
      setTimeout(() => {
        this.triggerTarget.innerHTML = originalContent
      }, 2000)
    }).catch(error => {
      console.error('Failed to copy: ', error)
    })
  }
}
