import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

export default class extends Controller {
  static targets = ['trigger']
  static values = {
    content: String
  }

  connect() {
    if (this.hasTriggerTarget && this.hasContentValue && this.contentValue.length > 0) {
      tippy(
        this.triggerTarget,
        {
          content: this.contentValue,
          allowHTML: true,
          theme: 'light-border'
        }
      )
    }
  }
}
