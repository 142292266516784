import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String
  }

  print() {
    const document = window.open(this.urlValue, '_blank')
    document.onload = () => document.print()
  }
}
